.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 20px; */
  }
  
  .message-feed {
    width: 90%;
    /* max-height: 400px; */
    /* border: 1px solid #ccc; */
    /* padding: 10px; */
    overflow-y: auto;
    overflow-x: hidden;
    /* background-color: #f9f9f9; */
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .message-feed::-webkit-scrollbar {
    display: none; 
  }

  .message {
    padding-bottom: 20px;
    border-radius: 5px;
    background-color: #fff;
    
  }

  .message div:first-child {
    
    font-size: .7em;
    padding: 8px 16px;
    border-radius: 20px;
    background-color: lightgrey;
    color: black;
  }


  .questions-bubble {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  .question-button {
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.2s ease-out;
  }
  
  .question-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
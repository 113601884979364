body, html {

  padding: 0;
  margin: 0;
  

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by most browsers */
}

html::-webkit-scrollbar {
  display: none; 
}

.App {
  position: relative;
  height: 100vh;
  width: 100vw;
  
}

.App .ContentContainer {
  width: 100%;
  height: 50vh;
  position: absolute;
  /* padding: 50px; */
  /* margin: 20px; */
  /* top: 50%; */
  /* left: 50%;  */
  /* transform: translate(-50%, 0%); */
  z-index: 10;
  color: blue; /* Adjust based on your sketch colors */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 20px; */
}

@keyframes expandLetterSpacing {
  from {
    letter-spacing: -0.21em;
    opacity: 10%;
  }
  to {
    letter-spacing: 2.5;
    opacity: 100%;
  }
}

.App .Name {
  text-align: center;
  font-weight: bold;
  font-size: 2.5em;

  font-size-adjust: auto;
  animation: expandLetterSpacing 3s;
}

.App .SiteName a {
  letter-spacing: .3em;
  text-decoration: None;
  color: inherit;
}

.ContentBox {
  overflow: auto;
  min-width: 200px;
   /* height: 100%; */
   margin: 20px;
   padding: 20px;
   background-color: white; 
   border: 1px solid black;
   box-shadow: rgba(211, 211, 211, 0.8) 10px 15px 0;

   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 10px;

   font-size: 1.5em;
}

.ContentBox a {
  text-decoration: None;
  color: inherit;
}